import { apiSlice } from "../../app/apiSlice";

export const SpecialistsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        Specialist: builder.mutation({
            query: () => ({
                url: "/getSpecialistsList",
                method: "GET",
            }),
        }),
        SpecialistProfile: builder.mutation({
            query: ({ id }) => ({
                url: `/getSpecialistsProfile/${id}`,
                method: "GET",
            }),
        }),
        SpecialistVideos: builder.mutation({
            query: ({ id }) => ({
                url: `/getSpecialistsVideos/${id}`,
                method: "GET",
            }),
        }),
        CallSchedule: builder.mutation({
            query: ({ specialist_id, call_scheduled_date }) => ({
                url: `/specialists/getAvailableTimeSlots`,
                method: "POST",
                body: { specialist_id, call_scheduled_date },
            }),
        }),
        BookSpecialist: builder.mutation({
            query: ({ specialist_id, call_scheduled_date, call_scheduled_time, call_scheduled_timezone, call_scheduled_reason, stripeToken, timeSlot, booking_id, call_status, is_member, currency, meeting_id }) => ({
                url: `/specialists/bookACall`,
                method: "POST",
                body: { specialist_id, call_scheduled_date, call_scheduled_time, call_scheduled_timezone, call_scheduled_reason, stripeToken, timeSlot, booking_id, call_status, is_member, currency, meeting_id: meeting_id ?? null },
            }),
        }),
        CallScheduleWithSpecialist: builder.mutation({
            query: ({ specialist_id }) => ({
                url: `/member/CallScheduleWithSpecialist`,
                method: "POST",
                body: { specialist_id },
            }),
        }),
        GetAvailableTime: builder.mutation({
            query: ({ specialist_id, call_scheduled_date }) => ({
                url: `/specialists/getAvailableTimeSlots`,
                method: "POST",
                body: { specialist_id, call_scheduled_date },
            }),
        }),


    }),
    overrideExisting: true,
});

export const {
    useSpecialistMutation,
    useSpecialistProfileMutation,
    useSpecialistVideosMutation,
    useCallScheduleMutation,
    useBookSpecialistMutation,
    useCallScheduleWithSpecialistMutation,
    useGetAvailableTimeMutation,
} = SpecialistsApiSlice