import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { MODAL_TYPES, useGlobalModalContext } from "../../../utils/GlobalModal";
import moment from "moment";
import { useBookSpecialistMutation, useCallScheduleMutation } from "../../Specialist/SpecialistApiSlice";
import { useScheduleInterviewMutation } from "../../Cruz/Api/InterviewApiSlice";
import { HorizontalCalender } from "../../../components/Date/HorizontalCalender";
import { CustomTimePicker } from "../../../components/Timepicker/CustomTimePicker";
import { Button } from "../../../components/Button/Button";
import defaultImage from '../../../assets/images/profile/Default.jpg';
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import Swal from "sweetalert2";
import { getUserData } from "../../../utils/Utils";

interface BookingProps {
    userId?: string;
    specialistName?: string;
    specialistLastName?: string;
    timeSlot: '1 hour' | '1hr' | '30 min';
    specialistDp: string | null;
    reason?: string;
    isOpen: boolean;
    toggleClose: () => void;
    changeTimeSlot: (timeslot: any) => void;
    bookingId?: string;
    from?: string;
    interviewStatus?: string;
    isRearrange?: boolean;
}
type Currency = "USD" | "GBP" | "EUR";
const today = moment().toDate();
export const BookModal = (props: BookingProps) => {
    const { userId, specialistName, specialistLastName, timeSlot, specialistDp, reason, isOpen, toggleClose, changeTimeSlot, bookingId, from, interviewStatus, isRearrange } = props;
    const userData = getUserData('userData');
    const currencySymbols: { [key: string]: string } = {
        USD: "$",
        EUR: "€",
        GBP: "£",
    };
    const [modal, setModal] = useState(false);
    const [callSheduleDate, setCallScheduleDate] = useState<Date | null>(today);
    const [selectedDate, setSelectedDate] = useState<Date | null>(today);
    const [selectedTime, setSelectedTime] = useState<string | null>();
    const [SelectedTimezone, setSelectedTimezone] = useState<string>('Europe/London');
    const [callSchedule] = useCallScheduleMutation();

    const [interviewSchedule] = useScheduleInterviewMutation();
    const [ErrorMsg, setErrorMsg] = useState("");
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [bookedTimes, setTimes] = useState<string[]>([]);
    const [BookSpecialist] = useBookSpecialistMutation();
    const [specialistCallRate, setSpecialistCallRate] = useState<Record<string, string>>({})
    const [specialistfee, setSpecialistFees] = useState<string>('');
    const [showTimeSlot, setShowTimeSlot] = useState<'1 hour' | '1hr' | '30 min'>(timeSlot);
    const [BtnText, setBtnText] = useState<string>('Confirm Date and Pay');
    const [currencySymbol, setCurrencySymbol] = useState<string>('£');
    const [currentCurrency, setCurrentCurrency] = useState<Currency>('GBP');
    const { hideModal, store } = useGlobalModalContext();
    const [call_status, setCallStatus] = useState<string>('P');
    const [isMember, setIsMember] = useState(1);
    const [showScheduleNow, setShowScheduleNow] = useState<boolean>(false);
    const fetchAvailableTime = async (date: moment.MomentInput) => {
        setIsLoading(true);
        try {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            if (from !== 'interview') {
                const response = await callSchedule({
                    specialist_id: userId,
                    call_scheduled_date: formattedDate,
                });
                if ('data' in response) {
                    const call_rates = response.data?.call_rates;
                    const getCurrency = response.data?.currency;
                    const symbol = currencySymbols[getCurrency];
                    setCurrentCurrency(getCurrency);
                    setCurrencySymbol(symbol);
                    setSpecialistCallRate(call_rates);
                    if (from !== 'Expert') {
                        setSpecialistFees(symbol + parseFloat(call_rates['one']));
                    }


                } else {
                    // If the response is an error, handle the error case
                    console.error('Error response:', response.error);
                }
            }
        } catch (error) {
            console.error('Failed to fetch specialist list:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (today && isOpen) {
            fetchAvailableTime(today);
        }
        if (from === 'Expert') {
            setCallStatus('R');
            setIsMember(0);
            setBtnText('Confirm')
        }
        if (from === 'interview') {
            setShowScheduleNow(true);
            setBtnText('Confirm Interview');
        }
    }, [today, isOpen, from]);

    const toggle = () => {
        if (modal) {
            setSelectedDate(today);
            setSelectedTime(null);
            setErrorMsg("");
            setSuccessMsg("");

            if (today) {
                handleDateSelect(today);
            }
        }
        setModal(!modal);
    };

    const handleDateSelect = async (date: Date) => {
        setSelectedDate(date);
        setCallScheduleDate(date);
        setIsLoading(true);
        try {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            const response = await callSchedule({
                specialist_id: userId,
                call_scheduled_date: formattedDate,
            });
            if ("error" in response) {
                throw new Error("Failed to schedule call");
            }
            const data = response.data.data;

            const transformedTime = data.map(
                (item: { call_scheduled_time: string }) =>
                    moment(item.call_scheduled_time, "HH:mm:ss").format("hh:mm A")
            );

            setTimes(transformedTime);
        } catch (error) {
            console.error("Failed to schedule call:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTimeChange = (time: string) => {
        setSelectedTime(time);
    };

    const changeTimeSlotHere = (timeSlotChange: any) => {
        changeTimeSlot(timeSlotChange);
        console.log('timeSlotChange----', specialistCallRate);
        if (specialistCallRate) {
            let timeAmount = parseFloat(specialistCallRate['one']);
            if (timeSlotChange === '30 min' || timeSlotChange === '30min') {
                timeAmount = parseFloat(specialistCallRate['half']);
            }
            if (from !== 'Expert') {
                setSpecialistFees(currencySymbol + timeAmount);
            }
        }
        setShowTimeSlot(timeSlotChange);

    };
    const [modal2Open, setModal2Open] = useState(false);
    const { showModal } = useGlobalModalContext();
    const handlePayment = () => {
        setIsLoading(true);
        try {
            const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
            let convertedTime = "";
            if (selectedTime) {
                convertedTime = moment(selectedTime, "hh:mm A").format("hh:mm A");
                if (bookingId) {
                    handleSubmit('');
                } else {
                    showModal(MODAL_TYPES.PAYMENT_MODAL, {
                        onCloseCallback: handleSubmit,
                        handleSubmit: (stripeToken: any) => {
                            handleSubmit(stripeToken);
                        }
                    });
                }

            } else {
                setErrorMsg("Please select a Time before booking");
            }

        } catch (error) {
            console.error("Failed to fetch specialist list:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleSubmit = async (stripeToken: any) => {

        if (stripeToken === 'Error') {
            return false;
        }
        setIsLoading(true);
        setModal2Open(!modal2Open);

        try {
            hideModal();
            console.log('selectedTime-------', selectedTime);
            const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
            let convertedTime = "";
            if (selectedTime) {
                convertedTime = moment(selectedTime, "hh:mm A").format("hh:mm A");
            } else {
                console.log("selectedTime is null or undefined");
            }
            if (from !== 'interview') {
                const response = await BookSpecialist({
                    specialist_id: userId,
                    call_scheduled_date: formattedDate,
                    call_scheduled_time: convertedTime,
                    call_scheduled_timezone: SelectedTimezone,
                    call_scheduled_reason: reason,
                    stripeToken: stripeToken,
                    timeSlot: showTimeSlot,
                    booking_id: bookingId,
                    call_status: call_status,
                    is_member: isMember,
                    currency: currentCurrency,
                });
                if ("error" in response) {
                    console.error("Error logging in:", response.error);
                    if (typeof response.error === "string") {
                        setErrorMsg(response.error); // Assign the error message if it's already a string
                    } else {
                        const fetchError = response.error as FetchBaseQueryError; // Type assertion
                        if (
                            fetchError.data &&
                            typeof fetchError.data === "object" &&
                            "error" in fetchError.data &&
                            typeof fetchError.data.error === "string"
                        ) {
                            setErrorMsg(fetchError.data.error);
                        } else {
                            setErrorMsg("An error occurred"); // Handle cases where error property doesn't exist
                        }
                    }
                    setSuccessMsg("");
                } else {
                    let title = 'Payment Done!';
                    let text = `Awaiting confirmation from ${specialistName} ${specialistLastName}`;
                    if (bookingId) {
                        title = 'New Time Proposed';
                        text = `Awaiting confirmation from user`;
                    }
                    Swal.fire({
                        title: `${title}`,
                        text: `${text}`,
                        icon: "success",
                        timer: 3000,
                        showConfirmButton: false,
                        backdrop: `
                rgba(255, 255, 255, 0.5)
                left top
                no-repeat
                filter: blur(5px);
              `,
                        background: '#fff',
                    });
                    setErrorMsg("");
                    const textarea = document.getElementById('call_scheduled_reason') as HTMLTextAreaElement | null;
                    if (textarea) {
                        textarea.value = '';
                    }

                    setTimeout(() => {
                        toggle(); // Close the modal
                        setSuccessMsg(""); // Clear success message
                    }, 50);

                    toggleClose();
                }
            } else {
                const response = await interviewSchedule({
                    interview_date: formattedDate,
                    interview_time: convertedTime,
                    interview_timezone: SelectedTimezone,
                    interview_id: bookingId,
                    status: interviewStatus,
                });
                if ("error" in response) {
                    console.error("Error logging in:", response.error);
                    if (typeof response.error === "string") {
                        setErrorMsg(response.error); // Assign the error message if it's already a string
                    } else {
                        const fetchError = response.error as FetchBaseQueryError; // Type assertion
                        if (
                            fetchError.data &&
                            typeof fetchError.data === "object" &&
                            "error" in fetchError.data &&
                            typeof fetchError.data.error === "string"
                        ) {
                            setErrorMsg(fetchError.data.error);
                        } else {
                            setErrorMsg("An error occurred"); // Handle cases where error property doesn't exist
                        }
                    }
                    setSuccessMsg("");
                } else {

                    let title = 'New Time Proposed';
                    let text = `Awaiting confirmation from user`;
                    Swal.fire({
                        title: `${title}`,
                        text: `${text}`,
                        icon: "success",
                        timer: 3000,
                        showConfirmButton: false,
                        backdrop: `
                rgba(255, 255, 255, 0.5)
                left top
                no-repeat
                filter: blur(5px);
              `,
                        background: '#fff',
                    });
                    setErrorMsg("");
                    const textarea = document.getElementById('call_scheduled_reason') as HTMLTextAreaElement | null;
                    if (textarea) {
                        textarea.value = '';
                    }

                    setTimeout(() => {
                        toggle(); // Close the modal
                        setSuccessMsg(""); // Clear success message
                    }, 50);

                    toggleClose();
                }
            }


        } catch (error) {
            console.error("Failed to fetch specialist list:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const ukTime = moment.tz('Europe/London');
    const handleJoinNow = async () => {
        const convertedTime = ukTime.format('HH:mm');
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        const response = await interviewSchedule({
            interview_date: formattedDate,
            interview_time: convertedTime,
            interview_timezone: SelectedTimezone,
            interview_id: bookingId,
            status: interviewStatus,
        });
        if ("error" in response) {
            console.error("Error logging in:", response.error);
            if (typeof response.error === "string") {
                setErrorMsg(response.error); // Assign the error message if it's already a string
            } else {
                const fetchError = response.error as FetchBaseQueryError; // Type assertion
                if (
                    fetchError.data &&
                    typeof fetchError.data === "object" &&
                    "error" in fetchError.data &&
                    typeof fetchError.data.error === "string"
                ) {
                    setErrorMsg(fetchError.data.error);
                } else {
                    setErrorMsg("An error occurred"); // Handle cases where error property doesn't exist
                }
            }
            setSuccessMsg("");
        } else {

            let title = 'New Time Proposed';
            let text = `Awaiting confirmation from user`;
            Swal.fire({
                title: `${title}`,
                text: `${text}`,
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
                backdrop: `
        rgba(255, 255, 255, 0.5)
        left top
        no-repeat
        filter: blur(5px);
      `,
                background: '#fff',
            });
            setErrorMsg("");
            const textarea = document.getElementById('call_scheduled_reason') as HTMLTextAreaElement | null;
            if (textarea) {
                textarea.value = '';
            }

            setTimeout(() => {
                toggle(); // Close the modal
                setSuccessMsg(""); // Clear success message
            }, 50);

            toggleClose();
        }
    };

    const cancelBookACall = () => {
        setShowTimeSlot('1 hour');
        changeTimeSlot('1 hour');
        toggleClose();
    }

    return (
        <>
            {
                isLoading ? (
                    <div className="page-loader" >
                        <div className="page-innerLoader">
                            <div className="spinner-border" role="status">
                                <img src={require("../../../assets/images/cruz/Frame.png")} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div >
                ) : ("")}
            <Modal
                isOpen={isOpen}
                toggle={toggleClose}
                className="sidebar-modal specialist-videomodal-page"
                fade={false}
            >
                <ModalBody>
                    <div className="specialist-videomodal">
                        <div className="row">
                            <div className="col-sm-12">
                                {from !== 'interview' && from !== 'Expert' && (
                                    <div className="bookcall-box">
                                        <h4 className="customHeading">
                                            BOOK A CALL WITH {specialistName}
                                        </h4>
                                        <p>A {showTimeSlot} call with {specialistName} will cost {specialistfee}. Please select your time and date below</p>
                                    </div>
                                )}
                                <div className="image-wrapper">
                                    <div className="imgbox">
                                        {specialistDp && (
                                            <img style={{ borderRadius: '50%' }}
                                                src={specialistDp}
                                                className="img-fluid applicant-one"
                                                alt=""
                                            />
                                        )}
                                    </div>
                                    <div className="imgbox">
                                        <img
                                            src={userData?.members_profile_picture ? userData.members_profile_picture : require("../../../assets/images/profile/Default.jpg")}
                                            alt="Profile"
                                            className="img-fluid"
                                            style={{ borderRadius: '50%' }}
                                        />
                                    </div>
                                </div>
                                <div className="date-picker-box">
                                    <HorizontalCalender
                                        onSelectDate={handleDateSelect}
                                        selected={selectedDate}
                                    />
                                </div>
                                <div>
                                    <button onClick={handleJoinNow} className={showScheduleNow ? "button-style ConfirmCall w-100 btn-dark" : "d-none"}>Schedule for Now</button>
                                </div>

                                <CustomTimePicker handleChange={handleTimeChange} selectedTime={selectedTime} selectedDate={callSheduleDate} timezoneTime={undefined} specialist_id={userId} changeTimeSlot={changeTimeSlotHere} from={from} isRearrange={isRearrange} timeSlot={timeSlot} />

                                <div className="error">{ErrorMsg}</div>
                                <div className="success">{SuccessMsg}</div>
                                {from !== 'interview' && from !== 'Expert' && (
                                    <div className="oneHourcall">
                                        <span>{showTimeSlot} Call Charge : {specialistfee}</span>
                                    </div>
                                )}
                                <Button
                                    theme="dark"
                                    onClick={() => handlePayment()}
                                    text={`${BtnText} ${specialistfee}`}
                                    className="ConfirmCall w-100"
                                    icon={true}
                                />
                                <div className="cancel">
                                    <a href="#" className="button-link" onClick={cancelBookACall}>
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
