import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useGlobalModalContext } from "../../utils/GlobalModal";
import "./PaymentModal.scss";
import { loadStripe, Token } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserData } from "../../utils/Utils";
import { faAngleRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const stripePromise = loadStripe('pk_test_51PY3xEFmlGMjyCeEiFnD1wEDeLi9DsPGraKsJWNi6xvtc9rks2QD9chlQ9BGCq59LrADm5tFahlbtp3Co8GcZlKQ00RZjc8Xew');
const currencySymbols: { [key: string]: string } = {
    USD: "$",
    EUR: "€",
    GBP: "£",
};

function PaymentModal() {
    const { hideModal, store } = useGlobalModalContext();
    const [modal1Open, setModal1Open] = useState(false);
    const { modalProps } = store || {};
    const userData = getUserData("userData");

    const toggleModal1 = () => {
        setModal1Open(!modal1Open);
    }

    const handleModalToggle = () => {
        hideModal();
    };
    const stripeTokenHandler = (stripeToken: string) => {

        if (modalProps.handleSubmit) {
            modalProps.handleSubmit(stripeToken);
        }
    }
    const [message, setMessage] = useState<string | null | undefined>(null);

    const [isTokenGenerated, setIsTokenGenerated] = useState(false);

    const handleTokenGenerated = () => {
        setIsTokenGenerated(true);
    };

    return (
        <Modal
            isOpen={true}
            centered
            toggle={toggleModal1}
            onClose={handleModalToggle}
            className="payementmodal"
        >
            <ModalBody>

                <ModalHeader toggle={handleModalToggle}>
                    PAYMENT
                </ModalHeader>
                <div className={modalProps.hideInstruction ? "d-none" : "payment-tabinfo"}>
                    <ul>
                        <li>
                            The expert needs to confirm the booking and may request a different time
                        </li>
                        <li>
                            If you cancel the booking up to 48hrs before you receive 75%. If you cancel the booking within 48hrs you do not get a refund
                        </li>
                        <li>
                            You can rearrange booking up to 48hrs before the consultation maximum once. Within 48hrs you cannot rearrange.
                        </li>
                    </ul>
                    <div className="info-icon">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                </div>
                <div className="login-box p-0">
                    <div className={modalProps.amount && userData?.currency ? "amount-box my-2" : "d-none"}>
                        <div className="p-2">
                            <div>
                                <h2 className="customHeading">Payment Amount</h2>
                            </div>
                            <div className="amount">
                                <span>{userData?.currency && currencySymbols[userData.currency]} {modalProps.amount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="login-box-inner">
                        <div className="row mx-0">

                            <Elements stripe={stripePromise}>
                                <CheckoutForm stripeTokenHandler={stripeTokenHandler} onTokenGenerated={handleTokenGenerated} from={"modal"} />
                            </Elements>
                            <div className="card-img">
                                <img src={require("../../assets/images/payment/cards/payment12.png")} alt="" className="img-fluid" />
                            </div>
                            <div className='d-flex align-items-center justify-content-end px-0'>
                                <button type="submit" form="checkout-form" id="submit-button" className="w-100 btn btn-pay mt-3">
                                    Pay Securely Now
                                    <span><FontAwesomeIcon icon={faAngleRight} /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default PaymentModal;
