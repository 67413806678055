import { Outlet } from "react-router-dom";
import { MainLayoutProps } from "../../types";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Suspense } from "react";
import { ChatList } from "../../features/ClubHouse/Chat/PrivateChat/ChatList";
import SingleChatMain from "../../features/ClubHouse/Chat/PrivateChat/SingleChatMain";
import EnvelopeIcon from "../../features/ClubHouse/Chat/PrivateChat/EnvelopeIcon";
import { useLocation } from 'react-router-dom';

export const MainLayout = (props: MainLayoutProps) => {

  const location = useLocation();

  return (
    <>
      {location.pathname === '/terms' || location.pathname === '/privacypage' ? (
        <main>
          {props.children}
        </main>

      ) : (
        <>
          <Header />
          <main>
            <Suspense
              fallback={
                <div className="page-loader">
                  <div className="page-innerLoader">
                    <div className="spinner-border" role="status">
                      <img src={require("../../assets/images/cruz/Frame.png")} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              }
            >
              {props.children}
            </Suspense>
          </main>
          <Footer />
          <ChatList />
          <SingleChatMain />
          <EnvelopeIcon />
        </>
      )}
    </>
  );
};
